// @cms-next src/stores/ACL.ts
const roleTree = {
  'area360-admin': {
    'portal-admin': {
      'seller-admin': {
        'seller-staff': {
          'manage-orgs': {
            'manage-customers': null
          },
          'manage-memberships': {
            'manage-customers': null
          },
          'payment-vouchers': null,
          'payment-cash': null,
          'apply-code': null,
          'sales-refund': null,
          'admissions-super': {
            'admissions-unredeem': {
              admissions: null
            },
            'admissions-force': {
              admissions: null
            }
          },
          sales: null,
          'sales-hold': null
        },
        'sales-super': {
          'sales-refund': null,
          sales: null,
          'sales-hold': null
        },
        'manage-roles': null,
        'manage-cash': null,
        'configure-events': null,
        'configure-ticketure': null,
        'configure-checkout-rules': null,
        'manage-savedmethod': null,
        'payment-savedmethod': null
      },
      reporting: {
        'reporting-author': null
      },
      developer: null,
      'manage-staff': null
    }
  },
  'read-only-all': {
    'read-only-orders': null,
    'read-only-events': null
  }
}

const virtualRoles = {
  portalSearch: ['manage-customers', 'admissions', 'sales', 'read-only-orders'],
  sellers: ['manage-customers', 'payment-vouchers', 'payment-cash', 'apply-code', 'sales-refund', 'admissions', 'sales', 'sales-hold', 'reporting-author', 'read-only-orders', 'read-only-events', 'manage-roles', 'manage-cash', 'configure-events', 'configure-ticketure', 'configure-checkout-rules', 'manage-savedmethod', 'payment-savedmethod']
}

export default class RoleTeller {
  constructor() {
    this.noSeller = new Set()
    this.portalStaffRoles = new Set()
    this.perSeller = new Map()
    this.perSellerStaff = new Map()
    this.dict = new Map()
    this.buildDict(null, roleTree)
    this.cache = new Map()
  }

  // Dump/list all roles for debugging
  getRoleSnapshot() {
    const info = {}
    if (this.noSeller.size) {
      info.tenant = Array.from(this.noSeller)
    }
    if (this.perSeller.size) {
      this.perSeller.forEach((set, sellerId) => {
        if (!info.seller) {
          info.seller = {}
        }
        info.seller[sellerId] = Array.from(set)
      })
    }
    return info
  }

  // build a quick reference map for telling what other roles a given one encompasses
  buildDict(role, map) {
    let dict = role && this.dict.has(role) ? this.dict.get(role) : new Set()
    Object.keys(map).forEach(k => {
      dict.add(k)
      if (map[k]) {
        dict = new Set([...dict, ...this.buildDict(k, map[k])])
      }
    })
    if (role && !this.dict.has(role)) {
      this.dict.set(role, dict)
    }
    return dict
  }

  add(role, sellerId) {
    role = this.get(role)
    if (role.name === 'portal-admin') {
      this.portalAdminRole = role
    }
    ;(sellerId ? this.ensureSellerSet(this.perSeller, sellerId) : this.noSeller).add(role.name)
    if (role.type === 'staff') {
      ;(sellerId ? this.ensureSellerSet(this.perSellerStaff, sellerId) : this.portalStaffRoles).add(role)
    }
  }

  /* eslint-disable */
  ensureSellerSet(map, sellerId) {
    let roles = map.get(sellerId)
    if (!roles) {
      roles = new Set()
      map.set(sellerId, roles)
    }
    return roles
  }
  /* eslint-enable */

  has(roleName, sellerId) {
    if (Array.isArray(roleName)) {
      for (let i = 0; i < roleName.length; i += 1) {
        if (this.has(roleName[i], sellerId)) {
          return true
        }
      }
      return false
    }
    if (virtualRoles[roleName]) {
      // sellerId = TRUE means that roles need to be checked in ALL seller scopes, and TRUE must be returned if role is found in ANY seller scope
      return this.has(virtualRoles[roleName], roleName === 'sellers')
    }
    sellerId = sellerId || (window.theApp.seller ? window.theApp.seller.id : false)
    const cacheKey = `${roleName}.${sellerId || ''}`
    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey)
    }
    let result = false
    const hasNoSellerRole = this.hasPortalRole(roleName)
    if (hasNoSellerRole || !sellerId) {
      result = hasNoSellerRole
    } else {
      let roles
      if (sellerId === true) {
        // ALL sellers - collect all roles from them all
        roles = new Set()
        this.perSeller.forEach(sSet => {
          roles = new Set([...roles, ...sSet])
        })
      } else {
        // Specific seller
        roles = this.perSeller.get(sellerId)
      }
      result = !!(roles && this.hasRole(roleName, roles))
    }
    this.cache.set(cacheKey, result)
    return result
  }

  hasPortalRole(role) {
    return this.hasRole(role, this.noSeller)
  }

  hasRole(role, ownSet) {
    if (ownSet.has(role)) {
      // the current user has "role" directly on itself
      return true
    }
    const ownRoles = Array.from(ownSet)
    let i = 0
    for (; i < ownRoles.length; i++) {
      if (this.dict.has(ownRoles[i]) && this.dict.get(ownRoles[i]).has(role)) {
        // one of the current user's roles encompasses "role"
        return true
      }
    }
    // the current user does not have "role" in any way
    return false
  }

  // Ensures that the same role object is used at all times for the same role IDs
  get(role) {
    if (!this.register) {
      this.register = new Map()
    }
    if (!this.register.has(role.id)) {
      this.register.set(role.id, role)
    }
    return this.register.get(role.id)
  }
}
